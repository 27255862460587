import React from "react";
import ReactDOM from "react-dom/client";

import moment from 'moment-timezone';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import reportWebVitals from "./reportWebVitals";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./components/ErrorPage";
import Login from "./auth/Login/Login";
import Main from "./components/main/Main";
// import Registration from "./auth/Registration/Registration";
import store from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import persistStore from "redux-persist/es/persistStore";
import Indice from "./components/content/Indice";
import Plati from "./components/content/Plati";
import Misc from "./components/content/Misc";
import Users from "./components/content/Users";
let persistor = persistStore(store);
moment.tz.setDefault("Europe/Chisinau");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Chisinau');


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Main />,
        children: [
          { path: "indice", element: <Indice /> },
          { path: "plati", element: <Plati /> },
          { path: "docs", element: <Misc /> },
          { path: "users", element: <Users /> },
        ],
      },
      {
        path: "login",
        element: <Login />,
      },
      // {
      //   path: "register",
      //   element: <Registration />,
      // },
    ],
  },
]);



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
          <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useEffect, useState } from "react";
import { List } from "antd";
import moment from "moment-timezone";
import { lastDoc} from "../../constants";
import { FileDoneOutlined } from "@ant-design/icons";

const FileList = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    async function getData() {
      try {
        const res = await lastDoc();
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    }
    getData();
  }, []);

  return (
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={<FileDoneOutlined />}
            title={item.name}
            description={
              <span>{moment(item.createdAt).format("DD/MM/YYYY, HH:mm")}</span>
            }
          />
        </List.Item>
      )}
    />
  );
};
export default FileList;

import React from "react";
import { Modal } from "antd";

const ModalLogout = ({ open, handleClose, handleOk }) => {
  return (
    <Modal
      title="Confirmați deconectarea"
      open={open}
      onOk={handleOk}
      onCancel={handleClose}
      okText="Da"
      cancelText="Anula"
    >
      <p>Sunteți sigur că doriți să vă deconectați?</p>
    </Modal>
  );
};

export default ModalLogout;

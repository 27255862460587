import axios from "axios";
import { API_URL } from "../constants";

const onLogin = (data) => {
  return axios.post(API_URL + "auth/login-admin", data);
};
const onRegister = (data) => {
  return axios.post(API_URL + "auth/register", data);
};

export { onLogin, onRegister };

import React, { useState } from "react";
import UsersTable from "./users/UsersTable";
import UsersModal from "./users/UsersModal";
import { Button } from "antd";

const Users = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const openUserModal = () => {
    setIsModalVisible(true);
  };
  return (
    <div className="d-flex gap-2 flex-column w-100 align-items-end">
      <Button
        type="primary"
        onClick={openUserModal}
        style={{ maxWidth: "200px" }}
      >
        Adăugați un utilizator nou
      </Button>
      <div className="w-100">
        <UsersTable
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      </div>
      <UsersModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </div>
  );
};

export default Users;

import { Button, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../constants";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import UsersModal from "./UsersModal";

const UsersTable = ({ isModalVisible, setIsModalVisible }) => {
  const [users, setUsers] = useState([]);
  const token = useSelector((state) => state.user.currentUser?.accessToken);

  const getUsers = async () => {
    try {
      const res = await axios.get(API_URL + "user/us/list", {
        headers: { Authorization: "Bearer " + token },
      });
      console.log(res.data);
      setUsers(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isModalVisible) getUsers();
  }, [isModalVisible]);

  const mainColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nume",
      dataIndex: "nume",
      key: "nume",
    },
    {
      title: "Prenume",
      dataIndex: "prenume",
      key: "prenume",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "User Customers",
      key: "userCustomersCount",
      render: (_, record) => record.UserCustomers.length,
    },
    {
      title: "Edit",
      key: "edit",
      width: "50px",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleEdit(record)}>
          Editare utilizator
        </Button>
      ),
    },
  ];

  const handleEdit = (record) => {
    setIsModalVisible({
      id: record.id,
      email: record.email,
      nume: record.nume,
      prenume: record.prenume,
      role: record.role.toLowerCase(),
      customers: record.UserCustomers.map((uc) => uc.customerId),
    });
  };

  const expandedRowRender = (record) => {
    const expandedColumns = [
      {
        title: "CONTRACT",
        dataIndex: ["Customer", "CONTRACT"],
        key: "CONTRACT",
      },
      {
        title: "DT_CONT",
        dataIndex: ["Customer", "DT_CONT"],
        key: "DT_CONT",
        render: (text) => (
          <span>
            {text ? moment(text).format("DD-MM-YYYY, HH:mm") : "Nu există date"}
          </span>
        ),
      },
      {
        title: "ABON",
        dataIndex: ["Customer", "ABON"],
        key: "ABON",
      },
      {
        title: "ADR_AB",
        dataIndex: ["Customer", "ADR_AB"],
        key: "ADR_AB",
      },
      {
        title: "KF_AB",
        dataIndex: ["Customer", "KF_AB"],
        key: "KF_AB",
      },
      {
        title: "TEL_AB",
        dataIndex: ["Customer", "TEL_AB"],
        key: "TEL_AB",
      },
    ];

    return (
      <Table
        columns={expandedColumns}
        dataSource={record.UserCustomers}
        pagination={false}
        rowKey={(record) => record.Customer.CONTRACT}
      />
    );
  };

  return (
    <>
      <Table
        columns={mainColumns}
        dataSource={users}
        expandable={{ expandedRowRender }}
        rowKey="id"
      />
      <UsersModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </>
  );
};

export default UsersTable;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TopicMenu from "../menu/TopicMenu";
import NavBar from "../navbar/Navbar";
import SideBar from "../sidebar/Sidebar";
import { App, Layout } from "antd";
import Plati from "../content/Plati";
import Indice from "../content/Indice";
import { ConfigProvider } from "antd";
import Misc from "../content/Misc";
import Users from "../content/Users";

const Main = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user.currentUser);
  const topics = [
    { name: "Indici", content: <Indice /> },
    { name: "Plati", content: <Plati /> },
    { name: "Documente diverse", content: <Misc /> },
    { name: "Utilizatori", content: <Users /> },
  ];
  const [contentIndex, setContentIndex] = useState(0);
  const [selectedKey, setSelectedKey] = useState("0");

  useEffect(() => {
    setSelectedKey(localStorage.getItem("tab"));
    setContentIndex(+localStorage.getItem("tab"));
  }, []);

  useEffect(() => {
    if (currentUser?.role !== "admin") {
      navigate("/login");
      sessionStorage.clear();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser?.role === "admin") {
      const parsedKey = parseInt(selectedKey);
      let nav = "indice";
      switch (parsedKey) {
        case 0:
          nav = "indice";
          break;
        case 1:
          nav = "plati";
          break;
        case 2:
          nav = "docs";
          break;
        case 3:
          nav = "users";
          break;

        default:
          break;
      }
      navigate(`/${nav}`);
    }
  }, [selectedKey, currentUser]);

  const changeSelectedKey = (event) => {
    const key = event.key;
    setSelectedKey(key);
    setContentIndex(+key);
    localStorage.setItem("tab", key);
  };
  const Menu = (
    <TopicMenu
      topics={topics}
      selectedKey={selectedKey}
      changeSelectedKey={changeSelectedKey}
    />
  );

  const token = {
    colorPrimary: "#f28737",
  };

  return (
    <div className="App">
      <ConfigProvider
        theme={{ token, components: { Button: { dangerColor: "#FF0000" } } }}
      >
        <App>
          <NavBar menu={Menu} />
          <Layout>
            <SideBar menu={Menu} />
            <Layout.Content className="content">
              {topics[contentIndex].content}
            </Layout.Content>
          </Layout>
        </App>
      </ConfigProvider>
    </div>
  );
};

export default Main;

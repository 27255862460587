import React, { useEffect, useState } from "react";
import IndiceUpload from "./indice/IndiceUpload";
import axios from "axios";
import { API_URL, getDailyIndice } from "../../constants";
import { DatePicker, Button, Table, theme, Empty, Skeleton } from "antd";
import useTableSearch from "../../hooks/useTableSearch";
import dayjs from "dayjs";
import moment from "moment-timezone";
import {useSelector} from "react-redux";

const { RangePicker } = DatePicker;
const dateFormat = "DD-MM-YYYY";

const Indice = () => {
  const [table, setTable] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [loading, setLoading] = useState(false);
  const accessToken = useSelector(
    (state) => state.user.currentUser?.accessToken
  );


  const [minDate, setMinDate] = useState(
    localStorage.getItem("minDateIndice")
      ? new Date(localStorage.getItem("minDateIndice"))
      : new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [maxDate, setMaxDate] = useState(
    localStorage.getItem("maxDateIndice")
      ? new Date(localStorage.getItem("maxDateIndice"))
      : new Date(new Date().setHours(23, 59, 59, 999))
  );
  const getColumnSearchProps = useTableSearch();
  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const newStartDate = new Date(dates[0]);
      newStartDate.setHours(0, 0, 0, 0);
      const newEndDate = new Date(dates[1]);
      newEndDate.setHours(23, 59, 59, 999);
      setDateRange([newStartDate, newEndDate]);
    } else {
      setDateRange([null, null]);
    }
  };
  const getTable = async () => {
    setLoading(true);
    try {
      const res = await axios.post(API_URL + "user/getIndice", {
        minDate: minDate,
        maxDate: maxDate,
      }, {
        headers: { Authorization: "Bearer " + accessToken }
      });
      if (res.data) {
        setTable(
          res.data.map((el) => {
            return { key: el.id, ...el };
          })
        );
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTable();
  }, []);

  const handleDownload = async () => {
    await getDailyIndice(minDate, maxDate);
  };

  function handleMinDate(date) {
    localStorage.setItem("minDateIndice", date);
    setMinDate(date);
  }

  function handleMaxDate(date) {
    localStorage.setItem("maxDateIndice", date);
    setMaxDate(date);
  }

  useEffect(() => {
    if (dateRange[0]) handleMinDate(dateRange[0]);
    if (dateRange[1]) handleMaxDate(dateRange[1]);
  }, [dateRange[0], dateRange[1]]);

  const columns = [
    {
      title: "Cod ID",
      dataIndex: "codId",
      key: "codid",
      sorter: (a, b) => a.codId - b.codId,
      ...getColumnSearchProps("codId"),
    },
    {
      title: "Contor ID",
      dataIndex: "contorId",
      key: "contorid",
      sorter: (a, b) => a.contorId.localeCompare(b.contorId),
      ...getColumnSearchProps("contorId"),
    },
    {
      title: "Indice Actuali",
      dataIndex: "indiceActuali",
      key: "indiceActuali",
      sorter: (a, b) => a.indiceActuali - b.indiceActuali,
      ...getColumnSearchProps("indiceActuali"),
    },
  ];

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <div className="d-flex flex-column gap-5">
      <div className="d-flex justify-content-between flex-column-reverse flex-xl-row gap-5">
        <div
          className="p-sm-5 p-4 d-flex w-100 flex-column gap-5"
          style={{ background: colorBgContainer, borderRadius: borderRadiusLG }}
        >
          <div className="row">
            <div>
              <div className="form-group">
                <label htmlFor="dateRangePicker">
                  Selectați intervalul de date:
                </label>
                <RangePicker
                  id="dateRangePicker"
                  style={{ width: "100%" }}
                  placeholder={["Data de începere", "Data finală"]}
                  defaultValue={[
                    dayjs(moment(minDate).format(dateFormat), dateFormat),
                    dayjs(moment(maxDate).format(dateFormat), dateFormat),
                  ]}
                  format={dateFormat}
                  onChange={handleDateRangeChange}
                />
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 flex-wrap">
            <div>
              <Button type="primary" onClick={getTable}>
                Afiseaza indicii
              </Button>
            </div>
            <div>
              <Button type="primary" onClick={handleDownload}>
                Descarca
              </Button>
            </div>
          </div>
        </div>
        <div className="w-100">
          <IndiceUpload />
        </div>
      </div>
      {loading ? (
        <Skeleton active />
      ) : (
        <Table
          locale={{
            triggerDesc: "Faceți clic pentru a sorta descrescător",
            triggerAsc: "Faceți clic pentru a sorta crescător",
            cancelSort: "Faceți clic pentru a anula sortarea",
            emptyText: <Empty description={"Nu există date"}></Empty>,
          }}
          scroll={{ x: 500 }}
          columns={columns}
          dataSource={table}
        />
      )}
    </div>
  );
};

export default Indice;

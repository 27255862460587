import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input, Select } from "antd";
import axios from "axios";
import { API_URL } from "../../../constants";
import { useSelector } from "react-redux";

const { Option } = Select;

export default function UsersModal({ setIsModalVisible, isModalVisible }) {
  const [form] = Form.useForm();
  const accessToken = useSelector(
    (state) => state.user.currentUser?.accessToken
  );
  const [options, setOptions] = useState([]);

  const handleCloseModal = () => {
    if (typeof isModalVisible === "object") {
      form.resetFields();
      setIsModalVisible(null);
    } else setIsModalVisible(false);
  };

  const getAllCustomers = async () => {
    try {
      const res = await axios.get(API_URL + "user/customers", {
        headers: { Authorization: "Bearer " + accessToken },
      });
      setOptions(
        res.data.map((item) => ({ value: item.CONTRACT, label: item.ABON }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      getAllCustomers();
      if (typeof isModalVisible === "object") {
        form.setFieldsValue(isModalVisible);
      } else {
        form.resetFields();
      }
    }
  }, [isModalVisible]);

  const postNewUser = async (values) => {
    const obj = {
      ...values,
    };
    try {
      const res = await axios.post(API_URL + "user/us/add", obj, {
        headers: { Authorization: "Bearer " + accessToken },
      });
      if (res.status === 200) {
        form.resetFields();
        setIsModalVisible(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateUser = async (values) => {
    const obj = {
      ...values,
      id: isModalVisible.id,
    };
    try {
      const res = await axios.post(API_URL + "user/us/update", obj, {
        headers: { Authorization: "Bearer " + accessToken },
      });
      if (res.status === 200) {
        form.resetFields();
        setIsModalVisible(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      title="Adăugați un utilizator nou"
      open={isModalVisible}
      onCancel={handleCloseModal}
      footer={null}
    >
      <Form
        form={form}
        autoComplete={"off"}
        layout="vertical"
        onFinish={typeof isModalVisible === "object" ? updateUser : postNewUser}
      >
        <Form.Item
          name="nume"
          label="Nume"
          rules={[
            {
              required: true,
              message: "Vă rugăm să introduceți numele dvs",
            },
          ]}
        >
          <Input placeholder="Introduceți numele dvs" />
        </Form.Item>{" "}
        <Form.Item
          name="prenume"
          label="Prenume"
          rules={[
            {
              required: true,
              message: "Vă rugăm să introduceți prenumele dvs",
            },
          ]}
        >
          <Input placeholder="Introduceți prenumele dvs" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Vă rugăm să introduceți adresa de e-mail",
            },
          ]}
        >
          <Input placeholder="Introduceți adresa de e-mail" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Parolă"
          rules={[
            { required: true, message: "Vă rugăm să introduceți parola" },
          ]}
        >
          <Input placeholder="Introduceți parola" />
        </Form.Item>
        <Form.Item
          name="role"
          label="Rol"
          rules={[{ required: true, message: "Vă rugăm să selectați rolul" }]}
        >
          <Select placeholder="Selectați rolul">
            <Option value="admin">Admin</Option>
            <Option value="gestionar">Gestionar</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="customers"
          label="Clienți"
          rules={[
            { required: true, message: "Vă rugăm să selectați un client" },
          ]}
        >
          <Select
            showSearch
            placeholder="Selectați clientul"
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={options}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
            Trimite
          </Button>
          <Button onClick={handleCloseModal}>Anulează</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
